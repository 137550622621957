import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    title as titleClass,
    subtitle as subtitleClass,
    description as descriptionClass,
    button as buttonClass,
    image,
} from './error.module.scss';
import { ISection, ISectionContentButton } from '../../models/section.model';

import Section from '../hoc/section';

export interface IErrorProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function Error({ className, section, TitleTag }: IErrorProps) {
    const { sectionId, content, style, css } = section;
    const { texts, media, buttons } = content;
    const [title, subtitle, description] = texts;
    const button: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleClass,
                subtitle: subtitleClass,
                description: descriptionClass,
                button: buttonClass,
            }}
            title={title}
            subtitle={subtitle}
            description={description}
            buttonText={button.text}
            buttonUrl={button.url}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <Image media={media} className={image} />
        </Section>
    );
}
